import React, { Component } from 'react';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import AccountsB from '../components/AccountsB';
import { DataGridPro ,GridToolbar } from '@mui/x-data-grid-pro';


const columns = [
    { field: 'id', headerName:'Instance ID',width: 200},
    { field: 'Name', headerName:'Name',width: 350},
    { field: 'Hostname', headerName:'Hostname',width: 400},
    { field: 'InstanceStatus', headerName:'Instance Status',width: 200}
];


class Hostnames extends Component {
   
   // Constructor 
   constructor(props) {
    super(props);

    this.state = {
        items: '',
        DataisLoaded: false,
        isLoading: false
    };
    this.handleChange = this.handleChange.bind(this);
}
async handleChange(event) {
    this.setState({isLoading:true});
    let account = [event.target.value]
    if (account[0] === "ALL"){
        let accountString = process.env.REACT_APP_ALL_ACCOUNTS
        account = accountString.split(',')
    }

    
    const allApis = [];
    const options = {method:'GET',headers: {'x-api-key':process.env.REACT_APP_API_KEY}}
    for(let i=0;i< account.length;i++){
        const apiUrl = `${process.env.REACT_APP_API_LINK}/IT-infra-data/inventory/${account[i]}`;
        const apiPromise = fetch(apiUrl,options);
        allApis.push(apiPromise);
    }
    this.setState({data:event.target.value});
    try {
        const responses = await Promise.all(allApis);
        const jsonapi = await Promise.all(responses.map(response => response.json()));
        let listitems = []
        for(let x=0;x< jsonapi.length;x++){
            jsonapi[x].forEach(function(item) {
                listitems.push(item);
                
            });
        }
        this.setState({ items: listitems , DataisLoaded: true,isLoading:false})
        
    } catch (error) {
        console.log(error);
    }
  
}
  render() {
    const { DataisLoaded, items ,data ,isLoading}  = this.state;
    if (!DataisLoaded && !data)  return ( <>
         
        <Container fluid ><h1 className="text-center">Server Hostnames</h1></Container>
        <Form.Select aria-label="Select Account" value={this.state.data} onChange={this.handleChange} >
            <AccountsB />   
        </Form.Select>
        </> );
    if (isLoading)  return ( <>
         
        <Container fluid ><h1 className="text-center">Server Hostnames</h1></Container>
        <Form.Select aria-label="Select Account" value={this.state.data} onChange={this.handleChange} >
            <AccountsB />   
        </Form.Select>
        <br></br>
        <Alert key='primary' variant='primary'> Loading Data.... </Alert>
        </> );
    if (items.message === "Internal server error" ) return ( <>
        <h1 className="text-center" >Server Hostnames</h1>
        <Alert key='warning' variant='warning'> Internal server Error Review Lambda </Alert>
        </>
         );
         
    return (
        <><Container fluid ><h1 className="text-center" >Server Hostnames</h1></Container>
        <Form.Select aria-label="Select Account" value={this.state.data} onChange={this.handleChange} >
            <AccountsB />   
        </Form.Select>
        <br></br>
        <div style={{ height: '700px', width: '100%' }}>
            <DataGridPro
            columns={columns}
            rows={this.state.items}
            components={{ Toolbar: GridToolbar }}
            initialState={{
            pagination: {
                pageSize: 25,
              },
            }}
            />
            </div> </>    
    );
}
}
   
export default Hostnames;