import React, { Component } from 'react';

import Form from 'react-bootstrap/Form';
import Box from '@mui/material/Box';


class ADStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };

        this.handleChange = this.handleChange.bind(this);
        
      }

      handleChange(event) {
        this.setState({isLoading:true});
        console.log([event.target.value])
        this.setState({Data:event.target.value});
        if (event.target.value === 'Main'){
            
        }
        console.log(this.state.Data)
        this.setState({isLoading:false});
      }

    render(){
    const { Data } = this.state
    if (Data === "Main" ) return ( <>
        <Box><h1 className="text-center">Active Directory Overview</h1></Box>
            
            <Form.Select aria-label="Select your Active Directory" value={this.state.data} onChange={this.handleChange} >
                <option> Select your Active Directory</option>
                <option value={"Main"}>Main Active Directory</option>
                <option value={"Test"}>Test Active Directory</option>
                <option value={"ALL"}>ALL Active Directoies</option>
            </Form.Select>
        <Box>
            <h2>Statistics</h2>
            <ul>
            <li>Number of users: 3876</li>
            <li>Number of computers: 1798</li>
            <li>Number of objects: 65</li>
            <li>Number of GPOs: 188</li>
            <li>Number of OUs: 14</li>
            </ul>
        </Box>
        <Box>
            <h2>Last Changes in Active Directory</h2>
            <table>
            <tr>
                <th>Date</th>
                <th>Objects</th>
                <th>Description</th>
            </tr>
            <tr>
                <td>01/01/2023</td>
                <td>User1</td>
                <td>Changed password</td>
            </tr>
            <tr>
                <td>01/02/2023</td>
                <td>Computer1</td>
                <td>Added to OU</td>
            </tr>
            </table>
        </Box>
        <Box>
            <h2>Active Directory Health Status</h2>
            <table>
            <tr>
                <th>AD Name</th>
                <th>Domain replication:</th>
                <th>Check schema replication:</th>
                <th>Check ADReplicationPartnerMetadata:</th>
                <th>Replication failure: </th>
                <th>AD Replication Connection:  </th>
                <th>AD Replication Site: </th>
                <th>Date Checked</th>
            </tr>
            <tr>
                <td>Main</td>
                <td>True</td>
                <td>True</td>
                <td>True</td>
                <td>True</td>
                <td>True</td>
                <td>True</td>
                <td>06/01/2023</td>
            </tr>

            </table>
        </Box>

    </> );
    if (Data === "Test" ) return ( <>
    <Box><h1 className="text-center">Active Directory Overview</h1></Box>
            
            <Form.Select aria-label="Select your Active Directory" value={this.state.data} onChange={this.handleChange} >
                <option> Select your Active Directory</option>
                <option value={"Main"}>Main Active Directory</option>
                <option value={"Test"}>Test Active Directory</option>
                <option value={"ALL"}>ALL Active Directoies</option>
            </Form.Select>
        <Box>
            <h2>Statistics</h2>
            <ul>
            <li>Number of users: 12</li>
            <li>Number of computers: 3</li>
            <li>Number of objects: 65</li>
            <li>Number of GPOs: 188</li>
            <li>Number of OUs: 14</li>
            </ul>
        </Box>
        <Box>
            <h2>Last Changes in Active Directory</h2>
            <table width={'100%'}>
            <tr>
                <th>Date</th>
                <th>Objects</th>
                <th>Description</th>
            </tr>
            <tr>
                <td>01/01/2023</td>
                <td>User1</td>
                <td>Changed password</td>
            </tr>
            <tr>
                <td>01/02/2023</td>
                <td>Computer1</td>
                <td>Added to OU</td>
            </tr>
            </table>
        </Box>
        <Box>
            <h2>Active Directory Health Status</h2>
            <table>
            <tr>
                <th>AD Name</th>
                <th>Domain replication:</th>
                <th>Check schema replication:</th>
                <th>Check ADReplicationPartnerMetadata:</th>
                <th>Replication failure: </th>
                <th>AD Replication Connection:  </th>
                <th>AD Replication Site: </th>
                <th>Date Checked</th>
            </tr>
            <tr>
                <td>Test</td>
                <td>True</td>
                <td>True</td>
                <td>False</td>
                <td>True</td>
                <td>True</td>
                <td>True</td>
                <td>01/01/2023</td>
            </tr>

            </table>
        </Box>
        

    </> );
    if (Data === "ALL" ) return ( <>
        <Box><h1 className="text-center">Active Directory Overview</h1></Box>
                
                <Form.Select aria-label="Select your Active Directory" value={this.state.data} onChange={this.handleChange} >
                    <option> Select your Active Directory</option>
                    <option value={"Main"}>Main Active Directory</option>
                    <option value={"Test"}>Test Active Directory</option>
                    <option value={"ALL"}>ALL Active Directoies</option>
                </Form.Select>
            <Box>
                <h2>Statistics</h2>
                <ul>
                <li>Number of users: 3888</li>
                <li>Number of computers: 1801</li>
                <li>Number of objects: 130</li>
                <li>Number of GPOs: 376</li>
                <li>Number of OUs: 28</li>
                </ul>
            </Box>
            <Box>
                <h2>Last Changes in Active Directory</h2>
                <table width={'100%'}>
                <tr>
                    <th>Date</th>
                    <th>Objects</th>
                    <th>Description</th>
                </tr>
                <tr>
                    <td>01/01/2023</td>
                    <td>User1</td>
                    <td>Changed password</td>
                </tr>
                <tr>
                    <td>01/02/2023</td>
                    <td>Computer1</td>
                    <td>Added to OU</td>
                </tr>
                </table>
            </Box>
            <Box>
            <h2>Active Directory Health Status</h2>
            <table>
            <tr>
                <th>AD Name</th>
                <th>Domain replication:</th>
                <th>Check schema replication:</th>
                <th>Check ADReplicationPartnerMetadata:</th>
                <th>Replication failure: </th>
                <th>AD Replication Connection:  </th>
                <th>AD Replication Site: </th>
                <th>Date Checked</th>
            </tr>
            <tr>
                <td>Main</td>
                <td>True</td>
                <td>True</td>
                <td>True</td>
                <td>True</td>
                <td>True</td>
                <td>True</td>
                <td>06/01/2023</td>
            </tr>
            <tr>
                <td>Test</td>
                <td>True</td>
                <td>True</td>
                <td>False</td>
                <td>True</td>
                <td>True</td>
                <td>True</td>
                <td>01/01/2023</td>
            </tr>

            </table>
        </Box>
    
        </> );
    return (
        <>
            <Box><h1 className="text-center">Active Directory Overview</h1></Box>
            <Form.Select aria-label="Select your Active Directory" value={this.state.data} onChange={this.handleChange} >
                <option> Select your Active Directory</option>
                <option value={"Main"}>Main Active Directory</option>
                <option value={"Test"}>Test Active Directory</option>
                <option value={"ALL"}>ALL Active Directories</option>
            </Form.Select>
            
        
        </> );
      
    
    }
}
export default ADStatus;