import React, { Component } from 'react';
import Header from './components/Header';

import { Routes, Route } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import Container from 'react-bootstrap/Container';

import NoAccess from './pages/NoAccess';
import Home from './pages/Home'
import PublicIp from './pages/PublicIp'
import LoadBalancers from './pages/LoadBalancers'
import CreateTag from './pages/CreateTag'
import Instances from './pages/Instances'
import VPCs from './pages/VPCs'
import Subnets from './pages/Subnets'
import VPCPeerings from './pages/VPCPeerings'
import CreateOffice365User from './pages/CreateOffice365User'
import Hostnames from './pages/HostNames';
import PeeringMap from './pages/PeeringMap';
import Software from './pages/Software';
import DeployCustomMetrics from './pages/DeployCustomMetrics';
import ADUsers from './pages/ADUsers';
import ADGroups from './pages/ADGroups';
import SecurityGroups from './pages/SecurityGroups';
import ADStatus from './pages/ADStatus';
import CostExplorer from './pages/CostExplorer';
import EBSSnapshots from './pages/EBSSnapshots';
import RemoveOffice365User from './pages/RemoveOffice365User';

import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(process.env.REACT_APP_LICENSE_KEY);


class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
          group:''
        };
      }
      async componentDidMount() {
      const user =  await Auth.currentAuthenticatedUser();
      const groupname = user.signInUserSession.accessToken.payload["cognito:groups"][0]
      this.setState({ group:groupname });
    
      }
    render() {
        const { group }= this.state
        if (group === "ReadOnly" )
        return (
            <div>
                <Header />
                <Container className="p-3">
                    <Container className="p-5 mb-4 bg-light rounded-3">
                        <Routes>
                        <Route index element={<Home />} />
                        <Route path="PublicIp" element={<PublicIp />} />
                        <Route path="LoadBalancers" element={<LoadBalancers />} />
                        <Route path="Instances" element={<Instances />} />
                        <Route path="VPCs" element={<VPCs />} />
                        <Route path="Subnets" element={<Subnets />} />
                        <Route path="VPCPeerings" element={<VPCPeerings />} />   
                        <Route path="Hostnames" element={<Hostnames />} />  
                        <Route path="PeeringMap" element={<PeeringMap />} />      
                        <Route path="Software" element={<Software />} />       
                        <Route path="ADUsers" element={<ADUsers />} />  
                        <Route path="ADGroups" element={<ADGroups />} />     
                        <Route path="ADStatus" element={<ADStatus />} />  
                        <Route path="SecurityGroups" element={<SecurityGroups />} />  
                        <Route path="CreateTag" element={<CreateTag />} /> 
                        <Route path="EBSSnapshots" element={<EBSSnapshots />} /> 
                        <Route path="*" element={<NoAccess />} />                            
                        </Routes>
                    </Container>
                </Container>
            </div>
        );
        if (group === "Admin")
        return (
            <div>
                <Header />
                <Container className="p-3">
                    <Container className="p-5 mb-4 bg-light rounded-3">
                        <Routes>
                            <Route index element={<Home />} />
                            <Route path="CreateTag" element={<CreateTag />} />  
                            <Route path="CreateOffice365User" element={<CreateOffice365User />} />  
                            <Route path="RemoveOffice365User" element={<RemoveOffice365User />} />  
                            <Route path="DeployCustomMetrics" element={<DeployCustomMetrics />} />     
                            <Route path="CostExplorer" element={<CostExplorer />} /> 
                            <Route path="PublicIp" element={<PublicIp />} />
                            <Route path="LoadBalancers" element={<LoadBalancers />} />
                            <Route path="Instances" element={<Instances />} />
                            <Route path="VPCs" element={<VPCs />} />
                            <Route path="Subnets" element={<Subnets />} />
                            <Route path="VPCPeerings" element={<VPCPeerings />} />   
                            <Route path="Hostnames" element={<Hostnames />} />  
                            <Route path="PeeringMap" element={<PeeringMap />} />      
                            <Route path="Software" element={<Software />} />       
                            <Route path="ADUsers" element={<ADUsers />} />  
                            <Route path="ADGroups" element={<ADGroups />} />     
                            <Route path="ADStatus" element={<ADStatus />} />  
                            <Route path="SecurityGroups" element={<SecurityGroups />} />  
                            <Route path="CreateTag" element={<CreateTag />} /> 
                            <Route path="EBSSnapshots" element={<EBSSnapshots />} />    
                            <Route path="*" element={<NoAccess />} />        
                        </Routes>
                    </Container>
                </Container>
            </div>
        );
        if (group === "ADUser")
        return (
            <div>
                <Header />
                <Container className="p-3">
                    <Container className="p-5 mb-4 bg-light rounded-3">
                        <Routes>
                            <Route index element={<Home />} />   
                            <Route path="ADUsers" element={<ADUsers />} />  
                            <Route path="ADGroups" element={<ADGroups />} />     
                            <Route path="ADStatus" element={<ADStatus />} />   
                            <Route path="CreateOffice365User" element={<CreateOffice365User />} />  
                            <Route path="RemoveOffice365User" element={<RemoveOffice365User />} />
                            <Route path="*" element={<NoAccess />} />                     
                        </Routes>
                    </Container>
                </Container>
            </div>
        );
    }
}

export default App;