import React, { Component } from 'react';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { DataGridPro ,GridToolbar } from '@mui/x-data-grid-pro';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const ADUsercolumns = [
    { field: 'id', headerName:'User',width: 300},
    { field: 'Manager', headerName:'Manager',width: 300},
    { field: 'Position', headerName:'Position',width: 200},
    { field: 'LastLogin', headerName:'Estimated Last Login',width: 200},
    { field: 'DistinguishedName', headerName:'Distinguished Name',width: 200},
    { field: 'Status', headerName:'Status',width: 200}
];
const ADMembershipcolumns = [
    { field: 'id', headerName:'Memberships',width: 600},
    
];

const UserHWcolumns = [
    { field: 'id', headerName:'ASSET TAG',width: 300},
    { field: 'UserEmail', headerName:'User Email',width: 300},
    { field: 'FullName', headerName:'Full Name',width: 300},
    { field: 'UserLogin', headerName:'UserLogin',width: 300},
    { field: 'AssetID', headerName:'AssetID',width: 300},
    { field: 'AssetSerial', headerName:'Asset Serial',width: 300},
    { field: 'AssetModel', headerName:'Asset Model',width: 300}
]

class ADUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Username:'',
            ADdata:'',
            ADMembership:'',
            UserHW:''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }

      handleChange(event) {
        const target = event.target;
        const value =  target.value;
        const name = target.name;

        this.setState({
        [name]: value
        });
      }

      handleSubmit = async (event) => {
        event.preventDefault();

        const ADUserDataResponse = await fetch(`${process.env.REACT_APP_API_LINK}/IT-infra-data/AD_users/${this.state.Username}`, 
        {method:'GET', 
        headers: { 'x-api-key':process.env.REACT_APP_API_KEY,
            'Content-Type': 'application/json' },
        })
        const ADUserData = await ADUserDataResponse.json();
        const ADMembershipResponse = await fetch(`${process.env.REACT_APP_API_LINK}/IT-infra-data/AD_user_memberships/${this.state.Username}`, 
        {method:'GET', 
        headers: { 'x-api-key':process.env.REACT_APP_API_KEY,
            'Content-Type': 'application/json' },
        })
        const ADMembershipData = await ADMembershipResponse.json();
        const SnipeITResponse = await fetch(`${process.env.REACT_APP_API_LINK}/IT-infra-data/snipeITuser/${this.state.Username}`, 
        {method:'GET', 
        headers: { 'x-api-key':process.env.REACT_APP_API_KEY,
            'Content-Type': 'application/json' },
        })
        const SnipeITData = await SnipeITResponse.json();
        this.setState({ADdata:ADUserData,
                        ADMembership:ADMembershipData,
                        UserHW:SnipeITData})
        console.log(this.state.ADdata)
        console.log(this.state.ADMembership)
        console.log(this.state.UserHW)
      }

    render(){
    const {ADdata  }  = this.state;
    if (!ADdata)  return (
        <>
        <Form onSubmit={this.handleSubmit}>
          <h1 className="text-center" >Search AD User</h1>
        <Form.Group className="mb-3" >
          <Form.Label>Username</Form.Label>
          <Form.Control required type="textarea" placeholder="First Name" name="Username" value={this.state.Username} onChange={this.handleChange} />
        </Form.Group>
        <Button variant="primary" type="submit">
        Search User
        </Button>
      </Form>
      </>
    );
    return (
        <>
        <Form onSubmit={this.handleSubmit}>
          <h1 className="text-center" >Search AD User</h1>
        <Form.Group className="mb-3" >
          <Form.Label>Username</Form.Label>
          <Form.Control required type="textarea" placeholder="First Name" name="Username" value={this.state.Username} onChange={this.handleChange} />
        </Form.Group>
        <Button variant="primary" type="submit">
        Search User
        </Button>
      </Form>
      <br></br>
        <div style={{ height: '200px', width: '100%' }}>
            <DataGridPro
            columns={ADUsercolumns}
            rows={this.state.ADdata}
            components={{ Toolbar: GridToolbar }}
            initialState={{
            pagination: {
                pageSize: 25,
              },
            }}
            />
            </div> 
            <Grid container spacing={2}>
            <Grid item xs={4}>
            <Paper>
            <div style={{ height: '700px', width: '100%' }}>
            <DataGridPro
            columns={ADMembershipcolumns}
            rows={this.state.ADMembership}
            components={{ Toolbar: GridToolbar }}
            initialState={{
            pagination: {
                pageSize: 25,
              },
            }}
            />
            </div>
            </Paper>
            </Grid>
            <Grid item xs={8}>
                <Paper>
            <div style={{ height: '700px', width: '100%' }}>
            <DataGridPro
            columns={UserHWcolumns}
            rows={this.state.UserHW}
            components={{ Toolbar: GridToolbar }}
            initialState={{
            pagination: {
                pageSize: 25,
              },
            }}
            />
            </div>
             </Paper>
      </Grid>
    </Grid>
      </>
    )
    }
}
export default ADUsers;