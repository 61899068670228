import React, { Component } from 'react';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import { DataGridPro ,GridToolbar } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';


const columns = [
    { field: 'id', headerName:'TAG',width: 400},
    { field: 'FirstMonth', headerName:'First Month',width: 150},
    { field: 'LastMonth', headerName:'Compare Month',width: 150},
    { field: 'Diference', headerName:'Diference $',width: 150},
    { field: 'Percent', headerName:'Diference %',width: 150},
    { field: 'FirstPeriod', headerName:'First Period',width: 300},
    { field: 'ComparePeriod', headerName:'Compare Period',width: 300},
];


  

class CostExplorer extends Component {
   
   // Constructor 
   constructor(props) {
    super(props);

    this.state = {
        items: '',
        DataisLoaded: false,
        isLoading: false,
        StartDate: '2023-01-01',
        EndDate: '2023-01-01'
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
}
handleChange(event) {
    const newDate = `${event.$y}-${event.$M+1}-${event.$D}`
    this.setState({StartDate:newDate})
}
handleChangeEnd(event) {
    const newEndDate = `${event.$y}-${event.$M+1}-${event.$D}`
    this.setState({EndDate:newEndDate});
}

async handleSubmit(event) {
    this.setState({isLoading:true});
    let account = [event.target.value]
    if (account[0] === "ALL"){
        let accountString = process.env.REACT_APP_ALL_ACCOUNTS
        account = accountString.split(',')
    }

    
    const allApis = [];
    const options = {method:'GET',headers: {'x-api-key':process.env.REACT_APP_API_KEY}}
    for(let i=0;i< account.length;i++){
        const apiUrl = `${process.env.REACT_APP_API_LINK}/IT-infra-data/cost_explorer/${this.state.StartDate}/${this.state.EndDate}`;
        const apiPromise = fetch(apiUrl,options);
        allApis.push(apiPromise);
    }
    this.setState({data:event.target.value});
    try {
        const responses = await Promise.all(allApis);
        const jsonapi = await Promise.all(responses.map(response => response.json()));
        let listitems = []
        for(let x=0;x< jsonapi.length;x++){
            jsonapi[x].forEach(function(item) {
                listitems.push(item);
                
            });
        }
        this.setState({ items: listitems , DataisLoaded: true,isLoading:false})
        
    } catch (error) {
        console.log(error);
    }
  
}
  render() {
    const { DataisLoaded, items  ,isLoading}  = this.state;
    if (!DataisLoaded && !isLoading)  return ( <>
        <Form >
        <h1 className="text-center" >Cost Explorer</h1>
        <p>This tool always try to compare full months of data, select 1st day in the left panel, and last day in the right panel to get more precise information</p>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Form.Group className="mb-3" >
          <DesktopDatePicker
          label="First Month"
          inputFormat="YYYY-MM-DD"
          value={this.state.StartDate}
          onChange={this.handleChange}
          renderInput={(params) => <TextField {...params} />}
        />
          
          <DesktopDatePicker
          label="Compare Month"
          inputFormat="YYYY-MM-DD"
          value={this.state.EndDate}
          onChange={this.handleChangeEnd}
          renderInput={(params) => <TextField {...params} />}
        />
         </Form.Group>
          </LocalizationProvider>
          <Button  type="button" onClick={this.handleSubmit}>
          Compare Months
          </Button>
          
          </Form>
        </> );
    if (isLoading)  return ( <>
        <Form >
        <h1 className="text-center" >Cost Explorer</h1>
        <p>This tool always try to compare full months of data, select 1st day in the left panel, and last day in the right panel to get more precise information</p>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Form.Group className="mb-3" >
          <DesktopDatePicker
          label="First Month"
          inputFormat="YYYY-MM-DD"
          value={this.state.StartDate}
          onChange={this.handleChange}
          renderInput={(params) => <TextField {...params} />}
        />
          
          <DesktopDatePicker
          label="Compare Month"
          inputFormat="YYYY-MM-DD"
          value={this.state.EndDate}
          onChange={this.handleChangeEnd}
          renderInput={(params) => <TextField {...params} />}
        />
          </Form.Group>
          </LocalizationProvider>
          <Button  type="button" onClick={this.handleSubmit}>
          Compare Months
          </Button>
         
          </Form>
        
        <br></br>
        <Alert key='primary' variant='primary'> Loading Data.... </Alert>
        </> );
    if (items.message === "Internal server error" ) return ( <>
        <h1 className="text-center" >Cost Explorer</h1>
        <Alert key='warning' variant='warning'> Internal server Error Review Lambda </Alert>
        </>
         );
         
    return (
        <>
        <Form >
        <h1 className="text-center" >Cost Explorer</h1>
        <p>This tool always try to compare full months of data, select 1st day in the left panel, and last day in the right panel to get more precise information</p>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Form.Group className="mb-3" >
          <DesktopDatePicker
          label="First Month"
          inputFormat="YYYY-MM-DD"
          value={this.state.StartDate}
          onChange={this.handleChange}
          renderInput={(params) => <TextField {...params} />}
        />
          
          <DesktopDatePicker
          label="Compare Month"
          inputFormat="YYYY-MM-DD"
          value={this.state.EndDate}
          onChange={this.handleChangeEnd}
          renderInput={(params) => <TextField {...params} />}
        />
         </Form.Group>
          </LocalizationProvider>
          <Button  type="button" onClick={this.handleSubmit}>
          Compare Months
          </Button>
          
          </Form>
        <br></br>
        <div style={{ height: '700px', width: '100%' }}>
            <DataGridPro
            columns={columns}
            rows={this.state.items}
            components={{ Toolbar: GridToolbar }}
            initialState={{
            
            }}
            />
            </div> </>    
    );
}
}
   
export default CostExplorer;