import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { Auth ,Amplify } from 'aws-amplify';
import { Authenticator,Image, useTheme ,View , Button} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

//import awsExports from './aws-exports';


const awsExports = {
  "aws_project_region": process.env.REACT_APP_PROJECT_REGION,
  "aws_cognito_identity_pool_id": process.env.REACT_APP_COGNITO_IDENTITY,
  "aws_cognito_region": process.env.REACT_APP_REGION,
  "aws_user_pools_id": process.env.REACT_APP_USER_POOL_ID,
  "aws_user_pools_web_client_id": process.env.REACT_APP_APP_CLIENT_ID,
  "oauth": {
    "domain": "it-infra-login.auth.us-east-1.amazoncognito.com",
    "scope": [
      "email", "openid"
  ],
  "redirectSignIn": "http://localhost:3000/", 
  "redirectSignOut": "http://localhost:3000/", 
  "responseType": "code"},
  "aws_cognito_username_attributes": [
      "EMAIL"
  ],
  "aws_cognito_social_providers": [],
  "aws_cognito_signup_attributes": [],
  "aws_cognito_mfa_configuration": "ON",
  "aws_cognito_mfa_types": [
      "TOTP"
  ],
  "aws_cognito_password_protection_settings": {
      "passwordPolicyMinLength": 8,
      "passwordPolicyCharacters": [
          "REQUIRES_LOWERCASE",
          "REQUIRES_NUMBERS",
          "REQUIRES_SYMBOLS",
          "REQUIRES_UPPERCASE"
      ]
  },
  "aws_cognito_verification_mechanisms": [
      "EMAIL"
  ]
};

Amplify.configure(awsExports);

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="logo"
          src="https://www.bairesdev.com/wp-content/uploads/2020/03/bairesdev-logo-facebook.png"
        />
      </View>
    );
  },
  SignIn: {
    Footer() {
     

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={() => Auth.federatedSignIn()}
          >
            SSO-AWS
          </Button>
        </View>
      );
    },
  },
};

const root = ReactDOM.createRoot(document.getElementById('root'));



root.render(
  <Authenticator  hideSignUp={true} components={components}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Authenticator>
);


