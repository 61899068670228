import React, { Component } from 'react';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';


class CreateOffice365User extends Component {
    constructor(props) {
        super(props);
        this.state = {
            FirstName:'',
            LastName:'',
            data:'',
            isLoading:false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }

      handleChange(event) {
        const target = event.target;
        const value =  target.value;
        const name = target.name;

        this.setState({
        [name]: value
        });
      }

      async handleSubmit() {
        
        console.log('Running fetch')
        this.setState({data:'', isLoading:true});
  
        const userData = {
            "FirstName": this.state.FirstName,
            "LastName":this.state.LastName
        }
        
        const json =  await fetch(`${process.env.REACT_APP_API_LINK}/IT-infra-data-Office365`, 
        {method:'POST', 
        headers: { 'x-api-key':process.env.REACT_APP_API_KEY,
            'Content-Type': 'application/json' },
        body: JSON.stringify(userData)
        })
        const data = await json.json()
        console.log("finished")
        this.setState({data:data, isLoading:false})
      }

    render(){
      const { data , isLoading}  = this.state;
      if (data === '' && !isLoading)
        return (
          <>
          <Form >
            <h1 className="text-center" >Create Office365 User</h1>
          <Form.Group className="mb-3" >
            <Form.Label>First Name for Email</Form.Label>
            <Form.Control required type="textarea" placeholder="First Name" name="FirstName" value={this.state.FirstName} onChange={this.handleChange} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Last Name for Email</Form.Label>
            <Form.Control required type="textarea" placeholder="Last Name" name="LastName" value={this.state.LastName} onChange={this.handleChange} />
          </Form.Group>
          <Button variant="primary" type="button" onClick={this.handleSubmit}>
          Create User
          </Button>
        </Form>
        </>
        );
        if (isLoading)
        return (
          <>
          <Form >
            <h1 className="text-center" >Create Office365 User</h1>
          <Form.Group className="mb-3" >
            <Form.Label>First Name for Email</Form.Label>
            <Form.Control required type="textarea" placeholder="First Name" name="FirstName" value={this.state.FirstName} onChange={this.handleChange} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Last Name for Email</Form.Label>
            <Form.Control required type="textarea" placeholder="Last Name" name="LastName" value={this.state.LastName} onChange={this.handleChange} />
          </Form.Group>
          <Button variant="primary" type="button" onClick={this.handleSubmit}>
          Create User
          </Button>
        </Form>
        <br></br>
        <Alert key='primary' variant='primary'> Creating User.... </Alert>
        </>
        );  
      return (
        <>
        <Form >
          <h1 className="text-center" >Create Office365 User</h1>
        <Form.Group className="mb-3" >
          <Form.Label>First Name for Email</Form.Label>
          <Form.Control required type="textarea" placeholder="First Name" name="FirstName" value={this.state.FirstName} onChange={this.handleChange} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Last Name for Email</Form.Label>
          <Form.Control required type="textarea" placeholder="Last Name" name="LastName" value={this.state.LastName} onChange={this.handleChange} />
        </Form.Group>
        <Button variant="primary" type="button" onClick={this.handleSubmit}>
        Create User
        </Button>
      </Form>
      <br></br>
      <Alert key='primary' variant='primary'> User Created.... </Alert>
      <h1>Response</h1>
      <h5>{this.state.data}</h5>
      </>
    )
    }
}
export default CreateOffice365User;