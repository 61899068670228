import React, { Component } from 'react';

class Home extends Component {
  render() {
    return (
        <div><br /><br /><br /><br />
          <h1 className="text-center" >Welcome to the IT-Infra Team Tools Page</h1>
        </div>
    );
  }
}

export default Home;