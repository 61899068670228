import React, { Component } from 'react';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { DataGridPro ,GridToolbar } from '@mui/x-data-grid-pro';


export const CustomDropdown = (props) => (
    <div className="form-group">
      <select
        className="form-control"
        value={props.value}
        onChange={props.handleChange}
      >
        <option defaultValue>Select </option>
        {props.options.map((item , index) => (
          <option key={index} value={item.id}>
            {item.id}
          </option>
        ))}
      </select>
    </div>
  )

const columns = [
    { field: 'id', headerName:'Users',width:600},
    
];


class ADGroups extends Component {
    constructor() {
        super();
        this.state = {
            Groupname:'',
            ADGroupUserData:'',
            ADGroupData:[]
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }

      handleChange = (event) => {
        this.setState({ Groupname: event.target.value });
      }

      handleSubmit = async (event) => {
        event.preventDefault();
        const ADGroupUserDataResponse = await fetch(`${process.env.REACT_APP_API_LINK}/IT-infra-data/AD_Group_Users/${this.state.Groupname}`, 
        {method:'GET', 
        headers: { 'x-api-key':process.env.REACT_APP_API_KEY,
            'Content-Type': 'application/json' },
        })
        const ADGroupUserData = await ADGroupUserDataResponse.json();
       
        this.setState({ADGroupUserData:ADGroupUserData})
      }


      async componentDidMount() {
        // Fetch the data from the API
        const ADGroupDataResponse = await fetch(`${process.env.REACT_APP_API_LINK}/IT-infra-data/AD_Groups`, 
        {method:'GET', 
        headers: { 'x-api-key':process.env.REACT_APP_API_KEY,
            'Content-Type': 'application/json' },
        })
        const ADGroupData = await ADGroupDataResponse.json();
        this.setState({ ADGroupData:ADGroupData });
      }
    
     

    render(){
    const {ADGroupUserData }  = this.state;
    if (!ADGroupUserData)  return (
        <>
        <Form onSubmit={this.handleSubmit}>
          <h1 className="text-center" >Search AD Group</h1>
            <Form.Group className="mb-3" >
                <Form.Label>Groupname</Form.Label>
                <CustomDropdown
                    value={this.state.Groupname}
                    options={this.state.ADGroupData}
                    handleChange={this.handleChange}
                    />
            </Form.Group>
        <Button variant="primary" type="submit">
        Search Group
        </Button>
      </Form>
      </>
    );
    return (
        <>
        <Form onSubmit={this.handleSubmit}>
          <h1 className="text-center" >Search AD Group</h1>
            <Form.Group className="mb-3" >
                <Form.Label>Groupname</Form.Label>
                <CustomDropdown
          value={this.state.Groupname}
          options={this.state.ADGroupData}
          handleChange={this.handleChange}
        />
            </Form.Group>
        <Button variant="primary" type="submit">
        Search Group
        </Button>
      </Form>
      <br></br>
        <div style={{ height: '700px', width: '100%' }}>
            <DataGridPro
            columns={columns}
            rows={this.state.ADGroupUserData}
            components={{ Toolbar: GridToolbar }}
            initialState={{
            pagination: {
                pageSize: 25,
              },
            }}
            />
            </div> 
      </>
    )
    }
}
export default ADGroups;