import React, { Component } from 'react';

 
class AccountsB extends Component {
  render(){
  return (
    <>
    <option> Select a Account</option>
    <option value={"IT BairesDev"}>IT BairesDev</option>
    </> );
  }
}
export default AccountsB;