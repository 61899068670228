import React, { Component } from 'react';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';



class DeployCustomMetrics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            instances:'',
            filename:'AmazonCloudWatch-IT-Infra-CW-Params'
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }

      handleChange(event) {
        const target = event.target;
        const value =  target.value;
        const name = target.name;

        this.setState({
        [name]: value
        });
      }

      async handleSubmit(event) {
        console.log('Running fetch')
        this.setState({data:'', isLoading:true});
        
        const CWData = {
            "instances": this.state.instances,
            "filename":this.state.filename
        }
        
        const json =  await fetch(`${process.env.REACT_APP_API_LINK}/IT-Infra-DeployCustomMetrics`, 
        {method:'POST', 
        headers: { 'x-api-key':process.env.REACT_APP_API_KEY,
            'Content-Type': 'application/json' },
        body: JSON.stringify(CWData)
        })
        event.preventDefault();
        const data = await json.json()
        console.log("finished")
        this.setState({data:data, isLoading:false})
      }

    render(){
    return (
        <Form onSubmit={this.handleSubmit}>
        <Form.Group className="mb-3" >
          <Form.Label>Inform the Instance IDs separated by comma (Max 30)</Form.Label>
          <Form.Control required as="textarea" rows={3} name="instances" value={this.state.instances} onChange={this.handleChange}/>
        </Form.Group>
        <Form.Group className="mb-3" >
          <Form.Label>File Name</Form.Label>
          <Form.Control required type="textarea"  name="filename" value={this.state.filename} onChange={this.handleChange} />
        </Form.Group>
        <Button variant="primary" type="button" onClick={this.handleSubmit}>
        Deploy CW Custom Metrics
        </Button>
      </Form>
    )
    }
}
export default DeployCustomMetrics;