import React, { Component } from 'react';

class NoAccess extends Component {
  render() {
    return (
        <div><br /><br /><br /><br />
          <h1 className="text-center" >You don't have access to this page</h1>
          <p className="text-center">Please reach administrator to give you this access.</p>
        </div>
    );
  }
}

export default NoAccess;