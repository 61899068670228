import React, { Component } from 'react';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Accounts from '../components/Accounts';
import { DataGridPro ,GridToolbar } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';

const options = {method:'GET',headers: {'x-api-key':process.env.REACT_APP_API_KEY}}
class SecurityGroups extends Component {
   
    // Constructor 
    constructor(props) {
        super(props);
   
        this.state = {
            items: '',
            DataisLoaded: false,
            isLoading: false,
            showBackdrop: false,
            SGInstances:[]
        };
        this.handleChange = this.handleChange.bind(this);
    }
    async handleChange(event) {
        this.setState({isLoading:true});
        let account = [event.target.value]
        if (account[0] === "ALL"){
            let accountString = process.env.REACT_APP_ALL_ACCOUNTS
            account = accountString.split(',')
        }
        const allApis = [];
        
        for(let i=0;i< account.length;i++){
            const apiUrl = `${process.env.REACT_APP_API_LINK}/IT-infra-data/security_groups/${account[i]}`;
            const apiPromise = fetch(apiUrl,options);
            allApis.push(apiPromise);
            }
        
        this.setState({data:event.target.value});
        try {
            const responses = await Promise.all(allApis);
            const jsonapi = await Promise.all(responses.map(response => response.json()));
            let listitems = []
            for(let x=0;x< jsonapi.length;x++){
                jsonapi[x].forEach(function(item) {
                  listitems.push(item);
                });
              }

            this.setState({ items: listitems, DataisLoaded: true, isLoading: false });
        } catch (error) {
            console.log(error);
        }
      }
    async handleClick(rowData){
        this.setState({ showBackdrop: true });
        let accountUser = this.state.data
        if (accountUser[0] === "ALL"){
            accountUser = this.state.account_full
        }
        const apiUrlUser = `${process.env.REACT_APP_API_LINK}/IT-infra-data/SG_Users/${accountUser}/${rowData.row.Region}/${rowData.id}`;
        const jsonUser = await fetch(apiUrlUser,options);
        const InstanceData = await jsonUser.json()
        this.setState({SGInstances:InstanceData})
        
    }
    handleClose = () => {
        this.setState({ showBackdrop: false });
    }
    
    
    render() {
        const { DataisLoaded, items ,data ,isLoading}  = this.state;
        // I created the columns const in here to use the this.handleClick
        const columns = [
            { field: 'GroupName', headerName:'Group Name',width: 450},
            { field: 'FromPort', headerName: 'From Port' ,width: 150},
            { field: 'ToPort', headerName: 'To Port' ,width: 150},
            { field: 'IpProtocol', headerName: 'Ip Protocol',width: 150 },
            { field: 'Instances', width: 200,renderCell: rowData => (
                <Button variant="contained" color="primary" onClick={() => this.handleClick(rowData)}>
                  Instances
                </Button>
              ),},
            { field: 'IpRanges', headerName: 'Ip Ranges',width: 700 },
            { field: 'Ipv6Ranges', headerName: 'Ipv6 Ranges',width: 700 },
            { field: 'PrefixListIds', headerName: 'Prefix List Ids',width: 700 },
            { field: 'App', headerName: 'App',width: 300 },
            { field: 'BusinessUnit', headerName: 'BusinessUnit',width: 300 },
            { field: 'Region', headerName: 'Region',width: 200 },
            { field: 'Account', headerName: 'Account',width: 200 },
            { field: 'id', headerName: 'id',width: 200 }
        
        ]
        const columnsSGInstances = [
            { field: 'id', headerName: 'InstanceID',width: 300 },
            { field: 'Name', headerName: 'Instance Name',width: 300 }
        ]
        if (!DataisLoaded && !data)  return ( <>
             
            <Container fluid ><h1 className="text-center">Security Groups</h1></Container>
            <Form.Select aria-label="Select Account" value={this.state.data} onChange={this.handleChange} >
                <Accounts />   
            </Form.Select>
            </> );
        if (isLoading)  return ( <>
             
            <Container fluid ><h1 className="text-center">Security Groups</h1></Container>
            <Form.Select aria-label="Select Account" value={this.state.data} onChange={this.handleChange} >
                <Accounts />   
            </Form.Select>
            <br></br>
            <Alert key='primary' variant='primary'> Loading Data.... </Alert>
            </> );
        if (items.message === "Internal server error" ) return ( <>
            <h1 className="text-center" >Security Groups</h1>
            
            <Alert key='warning' variant='warning'> Internal server Error Review Lambda </Alert>
            </>
             );
             
        return (
            <><Container fluid ><h1 className="text-center" >Security Groups</h1></Container>
            <Form.Select aria-label="Select Account" value={this.state.data} onChange={this.handleChange} >
                <Accounts />   
            </Form.Select>
            <Backdrop 
                open={this.state.showBackdrop} 
                onClick={this.handleClose}
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Box sx={{  bgcolor: 'white', height: '700px', width: '80%' }}>
                <DataGridPro
                columns={columnsSGInstances}
                rows={this.state.SGInstances}
                components={{ Toolbar: GridToolbar }}
                />
                </Box>
            </Backdrop>
            <br></br>
            <div style={{ height: '700px', width: '100%' }}>
            <DataGridPro
            columns={columns}
            rows={this.state.items}
            components={{ Toolbar: GridToolbar }}
            initialState={{
            pagination: {
                
              },
            }}
            />
            </div>
            </>    
    );
}
}
   
export default SecurityGroups;