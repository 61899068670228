import React, { Component } from 'react';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Accounts from '../components/Accounts';
import { DataGridPro ,GridToolbar } from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import { darken, lighten } from '@mui/material/styles';

const columns = [
    { field: 'Name', headerName:'Name',width: 300},
    { field: 'IP', headerName:'IP',width: 250, cellStyle: {
        whiteSpace: "pre-wrap"
      }},
    { field: 'id', headerName:'Target Group',width: 400},
    { field: 'instance0', headerName:'Target',width: 200},
    { field: 'port0', headerName:'TGT Port',width: 200},
    { field: 'health0', headerName:'TGT Health',width: 200},
    { field: 'instance1', headerName:'Secundary Target',width: 200},
    { field: 'port1', headerName:'Secondary TGT Port',width: 200},
    { field: 'health1', headerName:'Secondary TGT Health',width: 200},
    { field: 'DNS', headerName:'DNS',width: 700},
    { field: 'State', headerName:'State'},
    { field: 'App', headerName:'App'},
    { field: 'BusinessUnit', headerName:'BusinessUnit'},
    { field: 'Account', headerName:'Account',width: 200}
];

const getBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

class LoadBalancers extends Component {
   
    // Constructor 
    constructor(props) {
        super(props);
   
        this.state = {
            items: '',
            DataisLoaded: false,
            isLoading: false
        };
        this.handleChange = this.handleChange.bind(this);
    }
    // Handle account dropdown changes
    async handleChange(event) {
        //set state as loading to show the alert
        this.setState({isLoading:true});
        // Get the account from the form
        let account = [event.target.value]
        if (account[0] === "ALL"){
            let accountString = process.env.REACT_APP_ALL_ACCOUNTS
            account = accountString.split(',')
        }
        // create a list to save all api urls
        const allApis = [];
        // set the default options
        const options = {method:'GET',headers: {'x-api-key':process.env.REACT_APP_API_KEY}}
        // iterate the list to fetch all urls
        for(let i=0;i< account.length;i++){
            const apiUrl = `${process.env.REACT_APP_API_LINK}/IT-infra-data/lb_public_ip/${account[i]}`;
            const apiPromise = fetch(apiUrl,options);
            allApis.push(apiPromise);
            }
        
        this.setState({data:event.target.value});
        // get the response from the urls
        try {
            const responses = await Promise.all(allApis);
            const jsonapi = await Promise.all(responses.map(response => response.json()));
            // iterate the json to get the values and then save to a list
            let listitems = []
            for(let x=0;x< jsonapi.length;x++){
                jsonapi[x].forEach(function(item) {
                  listitems.push(item);
                });
              }
            // save the list in the state and remove the loading bar
            this.setState({ items: listitems, DataisLoaded: true, isLoading: false });
        } catch (error) {
            console.log(error);
        }
      }
      // render the component
    render() {
        const { DataisLoaded, items ,data ,isLoading}  = this.state;
        // show only the dropdown
        if (!DataisLoaded && !data)  return ( <>
             
            <Container fluid ><h1 className="text-center">LoadBalancer on AWS</h1></Container>
            <Form.Select aria-label="Select Account" value={this.state.data} onChange={this.handleChange} >
                <Accounts />   
            </Form.Select>
            </> );
        //show the loading bar 
        if (isLoading)  return ( <>
             
            <Container fluid ><h1 className="text-center">LoadBalancer on AWS</h1></Container>
            <Form.Select aria-label="Select Account" value={this.state.data} onChange={this.handleChange} >
                <Accounts />   
            </Form.Select>
            <br></br>
            <Alert key='primary' variant='primary'> Loading Data.... </Alert>
            </> );
          // show error messsage  
        if (items.message === "Internal server error" ) return ( <>
            <h1 className="text-center" >LoadBalancer on AWS</h1>
            
            <Alert key='warning' variant='warning'> Internal server Error Review Lambda </Alert>
            </>
             );
          // show the dropdown and the table   
        return (
            <><Container fluid ><h1 className="text-center" >LoadBalancer on AWS</h1></Container>
            <Form.Select aria-label="Select Account" value={this.state.data} onChange={this.handleChange} >
                <Accounts />   
            </Form.Select>
            <br></br>
            <Box
                sx={{
                    height: 700,
                    width: '100%',
                    '& .super-app-theme--Healthy': {
                    bgcolor: (theme) =>
                        getBackgroundColor(theme.palette.success.main, theme.palette.mode),
                    '&:hover': {
                        bgcolor: (theme) =>
                        getHoverBackgroundColor(
                            theme.palette.success.main,
                            theme.palette.mode,
                        ),
                    },
                    },
                    '& .super-app-theme--Partial': {
                    bgcolor: (theme) =>
                        getBackgroundColor(theme.palette.warning.main, theme.palette.mode),
                    '&:hover': {
                        bgcolor: (theme) =>
                        getHoverBackgroundColor(
                            theme.palette.warning.main,
                            theme.palette.mode,
                        ),
                    },
                    },
                    '& .super-app-theme--Unhealthy': {
                    bgcolor: (theme) =>
                        getBackgroundColor(theme.palette.error.main, theme.palette.mode),
                    '&:hover': {
                        bgcolor: (theme) =>
                        getHoverBackgroundColor(theme.palette.error.main, theme.palette.mode),
                    },
                    },
                }}
                >
            <DataGridPro
            getRowHeight={() => 'auto'} 
            columns={columns}
            rows={this.state.items}
            components={{ Toolbar: GridToolbar }}
            getRowClassName={(params) => `super-app-theme--${params.row.State}`}
            initialState={{
            pagination: {
              },
            }}
            />
            </Box>
            </>    
    );
}
}
   
export default LoadBalancers;