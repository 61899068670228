import React, { Component, createRef  } from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Accounts from '../components/Accounts';
import {  Network } from 'vis-network';


class PeeringMap extends Component {
    constructor() {
        super();
        this.network = {};
        this.appRef = createRef();
        this.state = {
            nodes:'',
            edges:'',
            DataisLoaded: false,
            isLoading: false,
            account: ''
        };
        this.handleChange = this.handleChange.bind(this);
    }
    async handleChange(event) {
        //check account from page
        let account = [event.target.value]
        // change the account for accounts array if all accounts selected
        if (account[0] === "ALL"){
            let accountString = process.env.REACT_APP_ALL_ACCOUNTS
            account = accountString.split(',')
        }
        //save account information to the state
        this.setState({account:account});
        
        //Create a map to remove duplicated nodes
        let nodesmap = {}
        //create node array
        let nodes = [] ; 
        //create edge array
        let edges = [];


        //Get Edges data from api and store the data in listedges
        const allApis = [];
        const optionsapi = {method:'GET',headers: {'x-api-key':process.env.REACT_APP_API_KEY}}
        for(let i=0;i< account.length;i++){
            const apiUrl = `${process.env.REACT_APP_API_LINK}/IT-infra-data/VPCPeerings/${account[i]}`;
            const apiPromise = fetch(apiUrl,optionsapi);
            allApis.push(apiPromise);
            }
    
        try {
            const responses = await Promise.all(allApis);
            const listedges = await Promise.all(responses.map(response => response.json()));
            for(let m=0;m< listedges.length;m++){
                // iterate the list inside the api list
                for(let k=0;k< listedges[m].length;k++){
                        // create the line that will be pushed to the the drawing method and the push it
                        let lineedge = {}                    
                        lineedge["from"] = listedges[m][k]["AccepterVpcInfo"]
                        lineedge["to"] = listedges[m][k]["RequesterVpcInfo"]
                        edges.push(lineedge)
                        //add the nodes from the edges, I send with external info, but if the node is internal the data will change in the nodes for loop
                        nodesmap[listedges[m][k]["AccepterVpcInfo"]] = (listedges[m][k]["AccepterVpcInfo"])
                        nodesmap[listedges[m][k]["RequesterVpcInfo"]] = (listedges[m][k]["RequesterVpcInfo"])
                }
            } 
        }catch (error) {
            console.log(error);
        }
        
        

        //Get nodes data from api and store the data in listnodes
        const allApisNodes = [];
        const optionsapiNodes = {method:'GET',headers: {'x-api-key':process.env.REACT_APP_API_KEY}}
        for(let i=0;i< account.length;i++){
            const apiUrlNodes = `${process.env.REACT_APP_API_LINK}/IT-infra-data/vpcs_all/${account[i]}`;
            const apiPromiseNodes = fetch(apiUrlNodes,optionsapiNodes);
            allApisNodes.push(apiPromiseNodes);
        }
    
        try {
            const responsesNodes = await Promise.all(allApisNodes);
            const listnodes = await Promise.all(responsesNodes.map(responseNodes => responseNodes.json()));
            // iterate the nodes list form the api 
            for(let n=0;n< listnodes.length;n++){     
                for(let k=0;k< listnodes[n].length;k++){
                    //add the nodes to the nodesmap with the id as label if the vpc doesn't have a name
                    if (listnodes[n][k]["VpcName"] === ""){
                        nodesmap[listnodes[n][k]["VpcId"]] = listnodes[n][k]["VpcId"]
                    }else{ //add with the name if it exists
                        nodesmap[listnodes[n][k]["VpcId"]] = listnodes[n][k]["VpcName"] 
                    }
                    
                }
            }
        }
        catch (error) {
        console.log(error);
        }
        
    
        // iterate the nodesmap and create the array that will be used for map drawing
        for(const key in nodesmap){
            let line = {}
            line["id"] = key
            line["label"] = nodesmap[key]
            nodes.push(line);
        }
        //create the data from nodes and edges
            const data = {
              nodes,
              edges
            };
        
        // set up map options, for size , physics and edges
            const options = {autoResize: true,
                height: '1200px',
                width: '100%',
                nodes:{
                    shape: "box"
                },
                edges:{
                    length: 400,
                    physics: true,
                    smooth: {
                        enabled: false
                    }
                },
                physics: {
                    solver: "repulsion",
                    repulsion: {
                      nodeDistance: 200 // Put more distance between the nodes.
                    }
                  }
                };
                this.setState({data:event.target.value});
        // create the network map
        this.network = new Network(this.appRef.current, data, options);
    }     


  render() {
        return ( //print the interface
            <><Container fluid ><h1 className="text-center" >Peering Map</h1></Container>
            <h5 className="text-center" > This Map take a while to load, please wait </h5>
            <Form.Select aria-label="Select Account" value={this.state.account} onChange={this.handleChange} >
                <Accounts />   
            </Form.Select>
            <br></br>
            <div ref = { this.appRef } /> 
            
            </>
            );
  }
}


export default PeeringMap;