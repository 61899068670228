import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {  Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Button from 'react-bootstrap/Button';
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';

 
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      group:''
    };
  }
  async componentDidMount() {
  const user =  await Auth.currentAuthenticatedUser();
  const groupname = user.signInUserSession.accessToken.payload["cognito:groups"][0]
  this.setState({ group:groupname });
  }
  signOut = () => {
    Auth.signOut()
    .then(data => console.log(data))
    .catch(err => console.log(err));
  }
  render(){  
  return (
    <div>
    <Navbar bg="dark" variant='dark' expand="lg">
      <Container>
        <Navbar.Brand as={Link} to="/" onClick={this.handleChange}>IT-Infra</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
          <NavDropdown title="Instances" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/Instances">InstanceList</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/Hostnames">Hostnames</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/CreateTag">Create Tag</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/Software">Software Inventory</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/EBSSnapshots">EBS Snapshots</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/DeployCustomMetrics">Deploy CW Custom Metrics</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Network" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/PublicIp">Public Ip</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/LoadBalancers">Load Balancers</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/VPCs">VPCs</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/Subnets">Subnets</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/VPCPeerings">VPC Peerings</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/PeeringMap">VPC Peerings Map</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/SecurityGroups">Security Groups</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Active Directory" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/CreateOffice365User">Create Office 365 User</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/RemoveOffice365User">Remove Office 365 User</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/ADUsers">AD User Info</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/ADGroups">Users from Group</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Administration" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/CostExplorer">Cost Explorer</NavDropdown.Item>
            </NavDropdown>
           
          </Nav>
        </Navbar.Collapse>
        <NavbarCollapse className="justify-content-end">
        <Button  onClick={this.signOut} >Sign Out</Button>
        </NavbarCollapse>
      </Container>
    </Navbar>
    </div>
    );

  }
  }

export default Header;