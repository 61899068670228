import React, { Component } from 'react';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

class CreateTag extends Component {
    constructor(props) {
        super(props);
        this.state = {
            instances:'',
            tagname:'',
            tagvalue:''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }

      handleChange(event) {
        const target = event.target;
        const value =  target.value;
        const name = target.name;

        this.setState({
        [name]: value
        });
      }

      handleSubmit(event) {
        alert('InstanceId: ' + this.state.instances + ' Tag Name: ' + this.state.tagname +
        ' Tag Value: ' + this.state.tagvalue + ' created .');
        const tagData = {
            "instances": this.state.instances,
            "tagname":this.state.tagname,
            "tagvalue":this.state.tagvalue
        }
        
       fetch(`${process.env.REACT_APP_API_LINK}/IT-Infra-CreateEc2Tag`, 
        {method:'POST', 
        headers: { 'x-api-key':process.env.REACT_APP_API_KEY,
            'Content-Type': 'application/json' },
        body: JSON.stringify(tagData)
        })
        event.preventDefault();
      }

    render(){
    return (
        <Form onSubmit={this.handleSubmit}>
        <Form.Group className="mb-3" >
          <Form.Label>Inform the Instance IDs separated by comma</Form.Label>
          <Form.Control required as="textarea" rows={3} name="instances" value={this.state.instances} onChange={this.handleChange}/>
        </Form.Group>
        <Form.Group className="mb-3" >
          <Form.Label>Tag Name</Form.Label>
          <Form.Control required type="textarea" placeholder="Key" name="tagname" value={this.state.tagname} onChange={this.handleChange} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Tag Value</Form.Label>
          <Form.Control type="textarea" placeholder="Value" name="tagvalue" value={this.state.tagvalue} onChange={this.handleChange} />
        </Form.Group>
        <Button variant="primary" type="submit">
        Deploy Tag
        </Button>
      </Form>
    )
    }
}
export default CreateTag;