import React, { Component } from 'react';

 
class Accounts extends Component {
  render(){
  return (
    <>
    <option> Select a Account</option>
    <option value={"IT BairesDev"}>IT BairesDev</option>
    <option value={"IT Test account"}>IT Test account</option>
    <option value={'BairesDev Website'}>BairesDev Website</option>
    <option value={'BDevVentures'}>BDevVentures</option>
    <option value={'Cadreon AWS'}>Cadreon AWS</option>
    <option value={'Canada Corporation Olympus'}>Canada Corporation Olympus</option>
    <option value={'Circles Bairesdev'}>Circles Bairesdev</option>
    <option value={'Trionfo'}>Trionfo</option>
    <option value={'BairesDev Audit'}>BairesDev Audit</option>
    <option value={"ALL"}>ALL Accounts</option>
    </> );
  }
}
export default Accounts;